const ClientOfferKeys = {
  AllFilteredClientOfferListItems: 'allFilteredClientOfferListItems',
  ClientOfferDetails: 'clientOfferDetails',
  AllClientOffersList: 'allClientOffersList',
}

const UserKeys = {
  CurrentUserBasicInfo: 'currentUserBasicInfo',
  UserIsAmbassador: 'userIsAmbassador',
  DefaultPaymentPlatform: 'defaultPaymentPlatform',
}

const OrderKeys = {
  CalorieMatrix: 'calorieMatrix',
  OrderDetailedInfo: 'orderDetailedInfo',
  PackageMetadata: 'packageMetadata',
  OrderFormOrderMetadata: 'orderFormOrderMetadata',
  UserCurrentOrder: 'userCurrentOrder',
}

const QueryClientKeys = {
  GeneralConfigurationSettings: 'generalConfigurationSettings',
  OrderMetadataExtrasByHeaderCode: 'extrasOrderMetadataByHeaderCode',
  HolidaysForThreeYears: 'holidaysForThreeYears',
  DeliveryTypeByPostcodeGroupId: 'deliveryTypeByPostcodeGroupId',
  LedgerCodesListItems: 'ledgerCodesListItems',
  Exclusions: 'exclusions',
  PostcodesUnassignedListItems: 'postcodesUnassignedListItems',
  DriversUnassignedListItems: 'driversUnassignedListItems',
  DeliveryServices: 'deliveryServices',
  UserRoles: 'userRoles',
  ReferralDetails: 'referralDetails',
  ValidatedDiscountCodeInfo: 'validatedDiscountCodeInfo',
  ValidatePostcode: 'validatePostcode',
  AllPricingDetails: 'allPricingDetails',
  OrderMetadataMealExtras: 'orderMetadataMealExtras',
  ConfigValueByCode: 'configValueByCode',
  KitchenSectionLookups: 'kitchenSectionLookups',
  AllFilteredAmbassadorList: 'allFilteredAmbassadorList',
  MealDayMealsAndRecipes: 'mealDayMealsAndRecipes',
  MealFrequencyAndCostData: 'mealFrequencyAndCostData',
  WeeklyMealCostData: 'weeklyMealCostData',
  ContentManagementByCodes: 'contentManagementByCodes',
  ContentManagementByCode: 'contentManagementByCode',
  SeasonlCampaignEnabledConfigSetting: 'seasonlCampaignEnabledConfigSetting',
  AmbassadorDetails: 'ambassadorDetails',
  UserDiscountUsageByType: 'userDiscountUsageByType',
  UserAmbassadorDiscountCodes: 'userAmbassadorDiscountCodes',
  UserAmbassadorLoyaltyTokenHistory: 'userAmbassadorLoyaltyTokenHistory',
  ManagedContentByType: 'managedContentByType',

  Order: {
    PendingOrderForCurrentUser: 'pendingOrderForCurrentUser',
    DeliveryScheduleDaysForOrder: 'deliveryScheduleDaysForOrder',
    OrderSummary: 'orderSummary',
    OrderPricingSummary: 'orderPricingSummary',
    OrderSpecList: 'orderSpecList',
    OrderSpec: 'orderSpec',
    OrderSpecListMealBalancingSummary: 'orderSpecListMealBalancingSummary',
  },

  Delivery: {
    DeliveryScheduleByOrderId: 'deliveryScheduleByOrderId',
    OngoingDeliverySchedulesByUserId: 'ongoingDeliverySchedulesByUserId',
    DeliveryInfosByOrderId: 'deliveryInfosByOrderId',
    DeliveryInfosByUserId: 'deliveryInfosByUserId',
    MinActionableDateByOrderId: 'minActionableDateByOrderId',
    UserDailyMenu: 'userDailyMenu',
    UserDeliveryInfos: 'userDeliveryInfos',
    WorkingDatesFromDate: 'workingDatesFromDate',
  },

  Holiday: {
    HolidaysPerYearCount: 'holidaysPerYearCount',
  },

  Postcode: {
    PostcodeSchemaByPostcodeGroupId: 'postcodeSchemaByPostcodeGroupId'
  },

  Customer: {
    CustomerReferralLink: 'customerReferralLink',
    CurrentUserOrderList: 'currentUserOrderList',
    CurrentUserCustomerInfo: 'currentUserCustomerInfo',
    CustomerOrderHistory: 'customerOrderHistory',
    UserAutoRenewStatus: 'userAutoRenewStatus',
    CurrentUserCurrentOrderBasicInfo: 'currentUserCurrentOrderBasicInfo',
    UserCreditBalance: 'userCreditBalance',
    CurrentUserBagCollectionLogs: 'currentUserBagCollectionLogs',
    CurrentUserBagCollectionRequestStatus: 'currentUserBagCollectionRequestStatus',
  },

  Affiliate: {
    CurrentUserAffiliateDiscountCodes: 'currentUserAffiliateDiscountCodes',
  },

  LoyaltyToken: {
    CurrentUserTokenSummary: 'currentUserTokenSummary',
    CurrentUserTokenLedger: 'currentUserTokenLedger',
    CurrentUserExpiringTokens: 'currentUserExpiringTokens',
    CurrentUserTokenGrantingHistory: 'currentUserTokenGrantingHistory',
  },

  User: {
    CurrentUserAccountInfo: 'currentUserAccountInfo',
  },

  Transaction: {
    AllTransactions: 'allTransactions',
  },

  CreditCashOut: {
    CreditCashOutRequests: 'creditCashOutRequests',
  },

  Lookup: {
    LookupsByHeaderCode: 'lookupsByHeaderCode',
    FoodBoxLookupsWithHeaders: 'foodBoxLookupsWithHeaders'
  },

  ActivityLog: {
    ActivitiesByFilter: 'activitiesByFilter',
  },

  CustomerMealPlan: {
    CustomerExclusionsByUser: 'customerExclusionsByUser',
    CustomerNutritionDetailsByUser: 'customerNutritionDetailsByUser',
    CustomerMealPlanByUser: 'CustomerMealPlanByUser',
  },

  UserPayment: {
    CurrentUserPaymentCards: 'currentUserPaymentCards',
  },

  ToGo: {
    FilteredToGoMeals: 'filteredToGoMeals',
    FilteredToGoExtras: 'filteredToGoExtras',
  },

  DiscountCode: {
    DiscountCodeSingUpHistory: 'discountCodeSingUpHistory',
  },

  FoodBox: {
    DisabledDeliveryWeeks: 'disabledDeliveryWeeks',
    UpcomingDeliveryWeeks: 'upcomingDeliveryWeeks',
    FilteredFoodBoxMenu: 'filteredFoodBoxMenu',
    AllMealVariations: 'foodBoxAllMealVariations',
    AllMealVariationsForMeal: 'foodBoxAllMealVariationsForMeal',
    MealVariation: 'foodBoxMealVariation',
    AllMenuItems: 'foodBoxAllMenuItems',
    MenuItem: 'foodBoxMenuItem',
    MenuItemToGoMappings: 'foodBoxMenuItemToGoMappings',
    MenuItemExtraMappings: 'foodBoxMenuItemExtraMappings',
    PricingSummary: 'pricingSummary',
    PricingValidation: 'pricingValidation',
    FoodBoxOrderSummary: 'foodBoxOrderSummary',
    FoodBoxOrderDetailsById: 'foodBoxOrderDetailsById',
    FoodBoxOrderUpdateDetailsById: 'foodBoxOrderUpdateDetailsById',
    CurrentAndUpcomingDeliveryWeeksByUserId: 'currentAndUpcomingDeliveryWeeksByUserId',
    FoodBoxSubscriptionStatus: 'foodBoxSubscriptionStatus',
    FoodBoxSubscriptionBasicInfo: 'foodBoxSubscriptionBasicInfo',
    AllFilteredFoodBoxOrderList: 'allFilteredFoodBoxOrderList',
    FilteredFoodBoxDailyDeliveryList: 'filteredFoodBoxDailyDeliveryList',
    FoodBoxDailyDeliverySummary: 'foodBoxDailyDeliverySummary',
    FoodBoxEarliestEffectiveDate: 'foodBoxEarliestEffectiveDate',
  },

  FeatureRegistry: {
    FeatureRegistry: 'featureRegistry',
  },

  Meal: {
    FffMealsListItems: 'fffMealsListItems',
    ListItemsForMealsWithVariations: 'listItemsForMealsWithVariations',
    MealRecipeListItemsForMeal: 'mealRecipeListItemsForMeal',
    MealVariationsForMeal: 'mealVariationsForMeal',
    MealVariationListItemsForMeal: 'mealVariationListItemsForMeal',
    AllMealVariations: 'allMealVariations',
    MealVariation: 'mealVariation',
  },

  Address: {
    ProviderPostcodes: 'providerPostcodes',
  },

  SalesTarget: {
    AffiliateBespokeSalesList: 'affiliateBespokeSalesList',
    AffiliateFoodBoxSalesList: 'affiliateFoodBoxSalesList',
  },

  ...OrderKeys,
  ...UserKeys,
  ...ClientOfferKeys
}

export default QueryClientKeys;