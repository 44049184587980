import { AuthResult } from "./account-dto.types";
import { CustomerOrderUpdateStatus, KeyReturnRequestDto } from "./customer-dto.types";
import { CustomerDto } from "./customer-store.types";
import { DeliveryPatternDto, DeliveryScheduleDayDto, DeliveryScheduleDaysProjectionDto, DeliveryScheduleDto } from "./delivery-dto.types";
import { DiscountCode } from "./discount-code.types";
import { DaysOfWeek, DeliveryContainerType, MealBalancingStatus, OrderPlacementType, PaymentChannel, PaymentType, SchemaApplicablePlatform, TransactionPlatform } from "./fff.enums";
import { FileItemDto } from "./file-dto.types";
import { MetadataDictionary, Order, OrderMetadataItem, WeeklyOrderListItemDto } from "./order-store.types";
import { PaymentResultContextDto } from './payment-dto.types';
import { PostCodeDeliveryType, PostcodeSchemaDto } from "./postcode-dto.types";
import { SearchFilterBase } from "./shared-dto.types";
import { SupplementOrderDto } from "./supplement.types";
import { TransactionCategory } from "./transaction-dto.types";
import { CardDetailsDto } from "./user-card.types";

export interface OrderStagingMacrosDto {
  protien: number,
  carbs: number,
  fat: number,
  calories: number,
  additionalCalories: number
}

export interface ExclusionsDto {
  id: string;
  name: string,
}

export interface MealPlanDto extends PaymentContextDto {
  mealsPerday?: number;
  customMealOptionId?: string;
  dailyCalories?: number;
  derivedCalories?: number;
  dailyProteinInGrams?: number;
  dailyCarbsInGrams?: number;
  dailyFatInGrams?: number;
  includeBreakfast?: boolean;
  includeVeggieMeals?: boolean;
  mealExtrasIds?: string[];
  mealExtras?: OrderMetadataItem[];
  nutritionPreferenceId?: string;
  payment?: number;
  regularCalories?: number;
  exclusionIds?: string[];
  exclusions?: ExclusionsDto[];
  minimumDeliverableCalories?: number;
}

export interface StripeTokenDto {
  email: string,
  stripeToken: string
}

export interface CreateOrderForUserDto {
  stripeToken?: string,
  cardId?: string
}

export interface OrerSummeryQueryParameters {
  Email?: string
  PackageId?: string
  Postcode?: string
  SpecialDelieryCharge?: number
  DiscoutCodeAmount?: number
  ToakenCreditAmount?: number
}

export interface Address {
  line1: string,
  line2: string,
  city: string,
  county: string
}

export interface AddressDto {
  id?: string;
  line1: string,
  line2: string,
  city: string,
  county: string,
  country: string,
  postCode: string,
  longitude?: string,
  latitude?: string
}

export interface DeliveryInfoStagingDto {
  addressId: string,
  displayName?: string,
  locationImage?: FileItemDto,
  instruction: string,
  address?: AddressDto
  isTimedDelivery?: boolean,
  isEveningDelivery?: boolean,
}

export interface DeliveryInfoDto {
  id: string;
  addressId: string,
  displayName?: string,
  locationImageId: string,
  locationImageUrl?: string,
  locationImage?: FileItemDto,
  instruction: string,
  address?: AddressDto,
  isPrimary?: boolean,
  isTimelyDelivery?: boolean,
  isTimelyDeliveryAllowed?: boolean,
  isEveningDelivery?: boolean,
  userId?: string,
  postcodeGroupId?: string,
  hasKey?: boolean,
  isNationalPostcode?: boolean,
  pendingKeyReturnRequest?: KeyReturnRequestDto
  charge: number;
  blockedDays?: string[];
  blockedDaysArray?: number[];
  waiverFeeThreshold?: number;
  deliveryContainerType?: DeliveryContainerType;
  schemaApplicablePlatform?: SchemaApplicablePlatform;
  deliverableDaysDisplayText?: string;
  displayLabel?: string;
  postCodeDeliveryType?: PostCodeDeliveryType;
  postcodeSchema?: PostcodeSchemaDto;
}

export interface DeliveryInfoSaveResultDto extends PaymentResultDto {
  effectiveDate: Date;
  bagDepositRequired: boolean;
  bagNeedToBeReturned: boolean;
  deliveryPatternsHaveBlockedDays: boolean;
  deliveryDaysHaveBlockedDays: boolean;
  blockedDays: string[];
}

export interface DeliveryInfoDeleteResultDto extends PaymentResultDto {
  bagDepositRequired: boolean;
  fffHasKey: boolean;
  blockedDaysForPrimaryDeliveryInfo?: DaysOfWeek[];
}

export interface DeleteDeliveryInfoRequestDto extends PaymentContextDto {
  deliveryInfoId: string;
}

export enum OrderStatus {
  Started = 0,
  Paused = 1,
  Resumed = 2,
  Cancelled = 3,
  Completed = 4,
  Pending = 5,
  Expired = 6,
  Drafted = 7
}

export enum FoodBoxDeliveryWeekType {
  Active = 1,
  Drafted = 2,
  Skipped = 3,
  Pending = 4,
  Completed = 5,
  Disabled = 6
}

export enum PaymentOption {
  Upfront = 0,
  Weekly = 1,
  Flexi = 2
}



export interface PaymentResultDto extends PaymentResultContextDto {
  paymentRequired: boolean,
  payment: number,
  cashOnlyPayment: number,
  creditAdded: number,
  creditToBeConsumed: number,
  availableTokens: number;
  tokenConversionRate: number;
  maximumUsableTokens: number;
  appliedDiscount: number;
  utilizedTokenAmount: number;
  paymentCard: CardDetailsDto;
  paymentPlatform: TransactionPlatform;
  customerOrderUpdateStatus: CustomerOrderUpdateStatus;
  customerConsentRequired: boolean;
  actionEffectiveDate: Date;
  orderId?: string;
}

export interface OrderPayment {
  orderId?: string,
  userId?: string,
  payment: number,
  creditToBeConsumed: number,
  tokensToBeConsumed: number,
  discount?: number,
  payPalTransactionId?: string,
  appliedDiscount: number;
  utilizedTokenAmount?: number;
  paymentPlatform: TransactionPlatform,
  paymentSourceId?: string;
  description?: string,
  transactionCategory?: TransactionCategory;
  paymentChannel?: PaymentChannel;
}

export interface CurrentPackageSummary {
  id: string,
  orderNo: number,
  orderStatus: number,
  totalOrderAmount: number,
  totalOrderPaid: number,
  nutrition: string,
  maternityStatus: string,
  goal: string,
  package: string,
  deliveryTime: string,
  deliveryTimeFrom: Date,
  deliveryTimeTo: Date,
  costPerBag: number,
  actualCostPerBag: number,
  mealExtrasCount: number,
  mealsPerDay: number,
  deliveryStartDate: string,
  deliveryEndDate: string,
  daysRemaining: number,
  daysCompleted: number,
  discount: number
  discountCode?: DiscountCode
  dailyProteinInGrams?: number,
  dailyCarbsInGrams?: number
  dailyFatInGrams?: number
  juicesCount?: number;
  shakesCount?: number;
  boostersCount?: number;
  bitesCount?: number;
  nextWeeklyPaymentDate?: Date;
  paymentOption?: PaymentOption;
  scheduledDays?: DeliveryScheduleDayDto[];
}

export interface CancelOrderDetailsDto {
  cancellationType: OrderCancellationType,
  actualReturnableAmount: number,
  actualExtraFee: number,
  actualCreditableAmount: number,
  paymentAmount: number,
  paymentResult?: PaymentResultDto,
  lastDeliveryDate?: Date,
  creditableAmountDueToSupplementOrders: number,
  undeliverableSupplementOrders?: SupplementOrderDto[],
}

export enum OrderCancellationType {
  NotAllowed = 0,
  PaymentRequired = 1,
  CreditAddition = 2,
  Completed = 3
}

export interface CancelOrderDto extends PaymentContextDto {
  orderId?: string;
  cancellationReasonId?: string,
  details?: string
  switchFlexiToCommit?: boolean;
}

export interface CancelOrderRequestDto {
  orderId: string;
  cancellationReasonId: string;
  cancellationReasonDetails: string;
}

export interface CountryDto {
  id: string,
  countryCode: string,
  name: string,
  phoneCode: string
}

export interface CountrySearchFilter {
  searchTerm: string,
  limit: number
}

export interface OrderDetailsDto {
  goal: string;
  nutrition: string;
  maternityStatus: string;
  dailyCalories: number;
  providableCalories?: number;
  mealsPerBag: number;
  dailyProteinInGrams: number;
  dailyCarbsInGrams: number;
  dailyFatInGrams: number;
  regularCalories: number;
  mealExtrasPerBag: number;
  extraIds?: string[];
  packageId: string;
  packageDays: number;
  packagePrice: number;
  goalDescription: string;
  startDate: Date;
  deliveryAddress: string;
  postcode: string;
  bagDeposit: number;
  totalDeliveryCharge: number;
  discountCode: string;
  discount?: number;
  tokensDeductionAmount?: number;
  availableTokens?: number;
  maximumUsableTokens?: number;
  subTotal: number;
  total: number;
  payable: number;
  mealBalancingStatus: MealBalancingStatus;
  paymentOption: PaymentOption;
  consumableCreditAmount: number;
  finalPayable: number;
  paymentCard?: CardDetailsDto;
  deliveryPattern: DeliveryPatternDto;
  pricingSummary: OrderPricingSummaryDto
}

export interface QuickReOrderConfigDto extends PaymentContextDto {
  packageId?: string;
  startDate?: Date;
  discountCode?: string;
  selectedTokens?: number;
  transactionId?: string;
  paymentPlatform?: TransactionPlatform;
  paymentOption?: PaymentOption;
  orderPlacementType?: OrderPlacementType;
}

export interface PackageOptionItem {
  value: string;
  label: string;
  noOfDays: number;
}

export interface OrderBasicDetail {
  id: string;
  createdDateUtc: Date;
  goal: string;
  nutritionPreference: string;
  package: string;
  status: OrderStatus;
  total: number;
  mealBalancingStatus: MealBalancingStatus;
  mealBalancingErrors: string;
  customerId: string;
  customerName: string;
  renewedFromOrderNo?: string;
}

export interface OrderSpecDto {
  order: Order;
  deliverySchedules: DeliveryScheduleDto[];
  deliveryScheduleDays: DeliveryScheduleDaysProjectionDto;
  holidays: Date[];
  currentPackageSummary?: CurrentPackageSummary;
  mealsMetadata: MetadataDictionary;
  customer: CustomerDto;
  mealExtras?: ExtrasListItemDto[];
  mealExclusions?: ExclusionsDto[];
  deliveryInfos?: DeliveryInfoDto[];
}

export interface ExtrasListItemDto {
  id: string;
  name: string;
  quantity: number;
}

export interface OrderSpecSearchFilter extends SearchFilterBase {
  createdDateUtc?: string;
  endDateUtc?: string;
  mealBalancingStatus: MealBalancingStatus | '';
  orderStatus: OrderStatus | '';
  activeOrdersOnly: boolean;
  orderPlacementType: OrderPlacementType | '';
}

export interface OrderFreeBagRequestDto {
  reason: string;
  departmentId: string;
  orderId: string;
  userId: string;
  freeBagCount: number
}

export interface OrderRequestDto {
  email?: string;
  paymentPlatform: TransactionPlatform;
  platformToken?: string;
  platformSourceId?: string;
  stripeToken?: string;
  stripeCardId?: string;
  checkoutToken?: string;
  checkoutSourceId?: string;
  payPalTransactionId?: string;
  paymentOption?: PaymentOption;
  paymentSessionId?: string;
  paymentType?: PaymentType;
  orderPlacementType?: OrderPlacementType
  platformPaymentId?: string;
}

export interface PayPalAuthorizationRequestDto {
  amount?: string,
}

export interface PayPalOrderResponseDto {
  orderId: string,
  payPalTransactionId?: string
  status: string
}

export interface SpotPaymentResult {
  transactionId: string,
  usedCreditAmount: number
}

export interface PackageMetadataFilter {
  weeklyPaymentEnabled: boolean;
  autoRenewable: boolean;
  promotional: boolean;
}

export interface OrderHistorySearchFilterDto extends SearchFilterBase {
  userId?: string
}

export interface OrderCreateResultDto extends PaymentResultContextDto {
  authResult: AuthResult;
  orderId?: string;
  orderNo?: number;
  customerNo?: number;
  cardAdditionWithoutPayment?: boolean;
}

export interface PauseOrderDto {
  minPauseDate: Date;
  maxPauseDate: Date;
  pauseAllowed: boolean;
  minBagCollectionDate: Date;
  bagCollectable: boolean;
}

export interface WeeklyOrderListResult {
  items: WeeklyOrderListItemDto[];
  total: number;
}

export interface TrialOrdersSearchFilter extends SearchFilterBase {
  fromDate?: Date;
  toDate?: Date;
}

export interface TrialOrderDto {
  id: string;
  name: string;
  email: string;
  createdDate: Date;
  endDate: Date;
  renewed: boolean;
  orderStatus: string;
}

export interface TrialOrdersSummaryDto {
  nonRenewedCount: number;
  renewalPercentage: number;
  totalCount: number;
}

export interface TrialOrderSearchResultsDto {
  items: TrialOrderDto[];
  total: number;
  renewedCount: number;
}

export interface FlexiOrdersSearchFilter extends SearchFilterBase {
  fromDate?: Date;
  toDate?: Date;
}

export interface FlexiOrderDto {
  id: string;
  name: string;
  email: string;
  createdDate: Date;
  nextPaymentDate: Date;
  renewed: boolean;
  orderStatus: string;
  weeklyPaymentCount: number;
  orderNo: number;
  userId: string;
}

export interface FlexiAnalyticsSummaryDto {
  totalOrderCount: number;
  pausedAndCancelledOrderCount: number;
  rollover: number;
  avgWeeklyPayment: number;
  avgPaidBagsPerWeek: number;
}

/** An interface of the Payment Request API is the primary access point into the API , and lets web content and apps accept payments from the end user. */
export interface PaymentRequestDto {
  paymentPlatform?: TransactionPlatform;
  paymentChannel?: PaymentChannel;
  paymentType?: PaymentType;
  token?: string;
  cardId?: string;
  platformCustomerId?: string;
  platformPaymentId?: string;
  billingAddress?: AddressDto;
}

export interface PaymentContextDto {
  allowPayment?: boolean;
  cardId?: string;
  payPalTransactionId?: string;
  transactionId?: string;
  paymentPlatform?: TransactionPlatform;
  paymentSessionId?: string;
  platformPaymentId?: string;
  paymentType?: PaymentType;
}

export interface OrderUpdateResultDto extends PaymentResultDto {
  undeliverableSupplementOrders?: SupplementOrderDto[];
}

export interface ResumeOrderDto extends PaymentContextDto {
  orderId?: string;
  resumeDate?: Date
}

export interface OrderPricingSummaryRequestDto {
  mealsPerDay: number;
  mealExtraIds: string[];
  packageId?: string;
  postcodeGroupId?: string;
  isTimedDelivery?: boolean;
  paymentOption?: PaymentOption;
  firstDeliveryDate?: Date;
  deliveryPattern?: DeliveryPatternDto;
  discountCode?: string;
  promotionalCode?: string;
  selectedTokens?: number;
}

export interface MaximumApplicableTokensRequestDto {
  mealsPerDay: number;
  mealExtraIds: string[];
  packageId?: string;
  postcodeGroupId?: string;
  paymentOption?: PaymentOption;
  firstDeliveryDate?: Date;
  deliveryPattern?: DeliveryPatternDto;
  discountCode?: string;
}

export interface OrderPricingSummaryDto {
  pricePerBag: number;
  totalBagPrice: number;
  totalDiscount: number;
  totalTokenAmount: number;
  totalDeliveryCharge: number;
  total: number;

  initialPayment: InitialPaymentDto;
  isValidDiscountCode?: boolean;
  maximumApplicableTokens?: number;
}

interface InitialPaymentDto {
  bagCount: number;
  totalBagPrice: number;
  tokenAmount: number;
  discountAmount: number;
  deliveryCharge: number;
  bagDeposit: number;
  weeklyPaymentDeposit: number;
  weeklyPaymentDepositBagCount: number;
  creditPayment: number;
  cashPayment: number;
}

export interface OrderBasicInfoDto {
  id: string;
  orderNo: number;
  status: OrderStatus;
  nutritionPreferenceDisplayText: string;
  nutritionPreferenceCode: string;
  goalDisplayText: string;
  totalBags: number;
  remainingBags: number;
  paymentOption: PaymentOption;
  renewed: boolean;
  lastDeliveryDate?: Date;
  pausedDate?: Date;
}

export interface MetadataQuantityMap {
  [key: string]: number;
}

export interface ExtraCategorySelectionsMap {
  [key: string]: string[];
}